import React from "react"
import { Slice } from "gatsby"

import Footer from "../footer-v2"

export default function Layout({
  children,
  fullWidth = false,
}: {
  children: React.ReactNode
  newsletter?: boolean
  fullWidth?: boolean
}) {
  return (
    <>
      <Slice alias="header" fullWidth={fullWidth} />
      <main>{children}</main>
      <Footer />
    </>
  )
}
