import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import cn from "../utils/classname"

const buttonVariants = cva(
  [
    "inline-flex items-center justify-center border",
    "whitespace-nowrap text-2 font-medium",
    "focus-visible:opacity-80",
    "disabled:bg-neutral-alpha-3 disabled:text-neutral-alpha-8",
  ],
  {
    variants: {
      variant: {
        solid: "border-transparent",
        soft: "border-transparent",
        outline: "border bg-transparent disabled:border-neutral-alpha-3",
        ghost: "border-transparent bg-transparent hover:bg-neutral-alpha-3",
      },
      color: {
        primary: "text-primary-12",
        neutral: "text-neutral-12",
      },
      size: {
        1: "px-3 py-1.5 rounded-md text-2",
        2: "px-4 py-2.5 rounded-md text-2",
        3: "px-5 py-3 rounded-lg text-3",
      },
    },
    compoundVariants: [
      {
        color: "primary",
        variant: "solid",
        className: [
          "bg-jar-primary-9 text-white",
          "hover:bg-primary-10 focus-visible:bg-primary-10",
        ],
      },
      {
        color: "primary",
        variant: "soft",
        className: [
          "bg-primary-alpha-3",
          "hover:bg-primary-alpha-4 focus-visible:bg-primary-alpha-4",
        ],
      },
      {
        color: "primary",
        variant: "outline",
        className: [
          "border-primary-alpha-8",
          "hover:bg-primary-alpha-3 focus-visible:bg-primary-alpha-3",
        ],
      },
      {
        color: "primary",
        variant: "ghost",
        className: [
          "hover:bg-primary-alpha-4 focus-visible:bg-primary-alpha-4",
        ],
      },
      {
        color: "neutral",
        variant: "solid",
        className: [
          "bg-neutral-12 text-neutral-1",
          "hover:bg-neutral-11 focus-visible:bg-neutral-11",
        ],
      },
      {
        color: "neutral",
        variant: "soft",
        className: [
          "bg-neutral-alpha-3",
          "hover:bg-neutral-alpha-4 focus-visible:bg-neutral-alpha-4",
        ],
      },
      {
        color: "neutral",
        variant: "outline",
        className: [
          "border-neutral-alpha-6",
          "hover:bg-neutral-alpha-3 focus-visible:bg-neutral-alpha-3",
        ],
      },
      {
        color: "neutral",
        variant: "ghost",
        className: [
          "hover:bg-neutral-alpha-3 focus-visible:bg-neutral-alpha-3",
        ],
      },
    ],
    defaultVariants: {
      variant: "ghost",
      color: "neutral",
      size: 2,
    },
  }
)

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color">,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, color, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, color, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { buttonVariants }
export default Button
